import React from "react";
import { isMobile } from "react-device-detect";
import Cursor from "./cursor";
import "../styles/layout.css";
import * as styles from "./layout.module.css";

const Layout = ({ children, projectListActive }) => {
  return (
    <div>
      {!isMobile && <Cursor projectListActive={projectListActive} />}
      <div className={!isMobile ? styles.noCursor : styles.cursor}>{children}</div>
    </div>
  );
};

export default Layout;
