import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";
import * as styles from "./cursor.module.css";

const CURSOR_TYPES = {
  DEFAULT: "DEFAULT",
  LINK: "LINK",
  SLIDESHOW: "SLIDESHOW"
};

const Cursor = ({ projectListActive }) => {
  const [cursorType, setCursorType] = useState(CURSOR_TYPES.DEFAULT);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);

  const setMousePosition = e => {
    setMouseX(e.pageX);
    setMouseY(e.pageY);
  };

  const setCursor = e => {
    if (projectListActive) {
      return;
    }

    if (e.target.tagName === "A") {
      setCursorType(CURSOR_TYPES.LINK);
    } else {
      setCursorType(CURSOR_TYPES.DEFAULT);
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener("mousemove", setMousePosition);
    }

    return () => {
      if (document) {
        document.removeEventListener("mousemove", setMousePosition);
      }
    };
  }, []);

  useEffect(() => {
    if (document) {
      document.addEventListener("mouseover", setCursor);
    }

    return () => {
      if (document) {
        document.removeEventListener("mouseover", setCursor);
      }
    };
  }, [projectListActive]);

  useEffect(() => {
    if (projectListActive) {
      setCursorType(CURSOR_TYPES.SLIDESHOW);
    } else {
      setCursorType(CURSOR_TYPES.DEFAULT);
    }
  }, [projectListActive]);

  if (!mouseX || !mouseY) {
    return null;
  }

  return (
    <div
      className={cn(styles.cursor, cursorType === CURSOR_TYPES.SLIDESHOW && styles.hide)}
      style={{ top: `${mouseY}px`, left: `${mouseX}px` }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={cn(styles.circle, cursorType === CURSOR_TYPES.LINK && styles.link)}
          cx="7"
          cy="7"
          r="7"
          fill="#E84600"
        />
        <circle cx="7" cy="7" r="6.5" stroke="#E84600" />
      </svg>
    </div>
  );
};

export default Cursor;
